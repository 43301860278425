<template>
  <el-card shadow="never">
    <div slot="header">
      <span class="iconfont icon-PlacesInfo">&nbsp;基本信息</span>
    </div>
    <el-form :model="form" ref="form" label-width="100px" size="small" style="width:600px">
      <el-form-item label="编号">
        <strong style="padding-left:10px">{{form.groupId}}</strong>
      </el-form-item>
      <el-form-item
        label="名称"
        prop="groupName"
        :rules="{ required: true, message: '请输入名称', trigger: 'blur' }"
      >
        <el-input v-model="form.groupName" maxlength="13" show-word-limit placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item
        label="地区"
        prop="optionValue"
        :rules="{ required: true, message: '请选择地区', trigger: 'change' }"
      >
        <el-cascader
          :options="options"
          v-model="form.optionValue"
          style="width:100%"
          placeholder="请选择地区"
          @change="handleChangeAddress"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="address"
        :rules="{ required: true, message: '请输入详细地址', trigger: 'blur' }"
      >
        <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item
        label="联系电话"
        prop="phone"
        :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
      >
        <el-input v-model="form.phone" maxlength="11" show-word-limit></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="备用联系人">
            <el-input v-model="form.contact1"></el-input>
          </el-form-item>
          <el-form-item
            label="备用电话"
            prop="phone1"
            :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
          >
            <el-input v-model="form.phone1" maxlength="11" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备用联系人">
            <el-input v-model="form.contact2"></el-input>
          </el-form-item>
          <el-form-item
            label="备用电话"
            prop="phone2"
            :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号格式',trigger: 'blur'}]"
          >
            <el-input v-model="form.phone2" maxlength="11" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="LOGO">
        <el-dropdown placement="top">
          <div>
            <div class="avatar-uploader-logo">
              <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!form.logoUrl"></i>
              <el-image
                v-else
                :src="fileUrl+form.logoUrl"
                style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="dialogEditFileUpload=true"
              style="padding:0"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
            <el-dropdown-item style="padding:0">
              <el-upload
                style="text-align:center"
                :action="actionUrl"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                :headers="header"
                :data="{type:0,category:'图标'}"
                :show-file-list="false"
                :on-success="handleEditSuccess"
                :before-upload="beforeEditUpload"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEditPlaces">保存</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditLogo" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getRegionListApi,
  getGroupListByGroupIdApi,
  editPlacesApi
} from "../../api/data";
import Aes from "../../utils/aes";
import FileUpload from "../../components/FileUpload";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      dialogEditFileUpload: false,
      options: [], //地区JSON
      form: {}
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initGroup();
    this.initRegion();
  },
  methods: {
    initGroup() {
      getGroupListByGroupIdApi({ groupId: this.placesInfo.groupId }).then(
        res => {
          if (res) {
            this.form = res.data;
            var optionValue = [
              res.data.province,
              res.data.city,
              res.data.region
            ];
            this.form.optionValue = optionValue;
          }
        }
      );
    },
    //初始化地区
    initRegion() {
      getRegionListApi().then(res => {
        if (res) this.options = res.data;
      });
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //修改logo
    onSubmitEditLogo() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.logoUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.logoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //地区回调
    handleChangeAddress(value) {
      this.form.province = value[0];
      this.form.city = value[1];
      this.form.region = value[2];
    },
    //保存基本信息
    onSubmitEditPlaces() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          editPlacesApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.$store.commit(
                "setPlacesInfo",
                Aes.encrypt(JSON.stringify(this.form))
              );
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang='scss'>
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>